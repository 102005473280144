import React from 'react';
import FirstBlog from './firstblog';
import MOU from './mou';
import Courses from './courses';
import Counceler from './counceler';




export default function Blog() {
  // const dispatch = useDispatch();
  // useEffect(()=>{
  //   dispatch();
  // },[dispatch] )
  return (
    <div>
      <FirstBlog />
      <MOU />
     

      <div class=" mx-4 text-start mb-10 flex sm:mx-16 lg:mx-28 mt-24">
        <h1 class='text-xl sm:text-2xl md:text-3xl text-center '> What are you interested in learning?</h1>
      </div>

      <Courses
        course1='Amazon Web Services' h1margin='-15px'  
        course2='Microsoft Azure' h2margin='3px'
        course3='Cloud Computing' h3margin='-3px'
        course4='DevOps Engineering' h4margin='-12px'
        img1='AWS-Logo.png'  mtimg1='10px'  img1width='96px' 
        img2='Azure-Logo.png'  img2width='40px' mtimg2='24px'
        img3='Cloud-Computing-Logo.png' img3width='40px' mtimg3='27px'
         img4='Devops-Logo.png'  img4width='64px'/>

      <Courses
        course1='Cyber Security' h1margin='-5px'
        course2='CISCO'     h2margin='-8px'
        course3='Microsoft' h3margin='5px'
        course4='RedHat'   h4margin='-13px'
        img1='Cyber-Security-Logo.png' img1width='48px'  mtimg1='26px'
         img2='Cisco-Logo.png'  img2width='60px'  mtimg2='18px'
         img3='Windows-Logo.png' img3width='40px' mtimg3='32px'
         img4='Redhat-Logo.png' img4width='60px' mtimg4='21px' /> 

<Courses
        course1='Web Development' h1margin='-6px'
         course2='Frontend Development' h2margin='-6px'
         course3='Backend Development' h3margin='-12px'
        course4='MERN Development'
        img1='Web-Development-Logo.png'  img1width='50px'  mtimg1='27px' 
        img2='Frontend-Development-Logo.png'  img2width='52px'  mtimg2='24px'
        img3='Backend-Development-Logo.png'  img3width='60px' mtimg3='20px'
        img4='Mernstack-Developer-Logo.png' img4width='40px' mtimg4='25px' />


      <Courses
        course1='Python' h1margin='-5px'
        course2='Java' h2margin='-20px'
        course3='C & C++' h3margin='-27px'
        course4='Database Management'
        img1='Python-Logo.png'   img1width='50px'  mtimg1='27px'
        img2='Java-Logo.png'  img2width='72px'  mtimg2='21px'
        img3='C-&-C++-Logo.png' img3width='95px' mtimg3='px'
        img4='Database-Logo.png' img4width='50px' mtimg4='22px' />

<Courses
        course1='React'  h1margin='-5px'
        course2='Node.js' h2margin='-14px'
        course3='Django'  h3margin='-5px'
        course4='Java Script' h4margin='-5px'
        img1='React-Logo.png'  img1width='50px'  mtimg1='27px'
        img2='Node-Logo.png'    img2width='72px'  mtimg2='14px'
        img3='Django-Logo.png'   img3width='48px' mtimg3='28px'
        img4='Javasript-Logo.png'  img4width='45px' mtimg4='28px' />

<Courses
        course1='Data Science' 
        course2='AI & ML' 
        course3='MySql' h3margin='-15px'
        course4='MongoDb' h4margin='-9px'
        img1='Data-Science-Logo.png'  img1width='50px'  mtimg1='24px'
        img2='AI-Logo.png'            img2width='50px'  mtimg2='24px'
        img3='My-Sql-Logo.png'        img3width='80px' mtimg3='9px'
        img4='Mongo-Db-Logo.png'      img4width='60px' mtimg4='20px' />


      <Counceler/>
    </div>
  )
}

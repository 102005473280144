import React from 'react'
import './course.css'
import { Link } from 'react-router-dom';

export default function Courses(props) {
// for first card 
    const marginStyleforfirstcard = {
        marginTop: props.mtimg1,
      
        // Add other styles as needed
      };

     

// for second card 
const marginStyleforSecondcard = {
    marginTop: props.mtimg2,
  
    // Add other styles as needed
  };
// For third card
  const marginStyleforThirdcard = {
    marginTop: props.mtimg3,
  
    // Add other styles as needed
  };

  // For fourth card
  const marginStyleforFourthcard = {
    marginTop: props.mtimg4,
  
    // Add other styles as needed
  };

  const marginStyleforh1 = {
    marginTop: props.h1margin,
  
    
  };

  const marginStyleforh2 = {
    marginTop: props.h2margin,
  };

  const marginStyleforh3 = {
    marginTop: props.h3margin,
  };

  const marginStyleforh4 = {
    marginTop: props.h4margin,
  
    
  };

    return (
        <>
           
            <div class='mx-4 sm:mx-16 lg:mx-28  mt-3  md:mt-10 grid md:grid-cols-4  grid-cols-2 gap-3 lg:gap-10   '>
              <Link to='https://www.netligent.com/Courses'>
                <div className='overflow-hidden rounded justify-center box'>
                    <div className='flex justify-center'>
                        <img src={props.img1} className='' width={props.img1width} style={marginStyleforfirstcard}  />
                       
                    </div>
                    <h1 className=' mb-5 mx-10 justify-center flex ' style={marginStyleforh1} >{props.course1}</h1>
                </div> </Link>

                <Link to='https://www.netligent.com/Courses'>
                <div className='overflow-hidden rounded justify-center  box '>
                    <div className='flex justify-center'>
                        <img src={props.img2} className='  ' width={props.img2width}   style={marginStyleforSecondcard}/>
                    </div>
                    <h1 className='mb-5 mx-10 justify-center flex' style={marginStyleforh2}>{props.course2}</h1>
                </div>
                </Link>
                <Link to='https://www.netligent.com/Courses'>
                <div className='overflow-hidden rounded justify-center  box'>
                    <div className='flex justify-center'>
                        <img src={props.img3} className='  ' width={props.img3width} style={marginStyleforThirdcard} />
                    </div>
                    <h1 className=' mb-5 mx-10 justify-center flex' style={marginStyleforh3} >{props.course3}</h1>
                </div> </Link>
                <Link to='https://www.netligent.com/Courses'>
                <div className='overflow-hidden rounded justify-center  box'>
                    <div className='flex justify-center'>
                        <img src={props.img4} className=' mt-3 '  width={props.img4width} style={marginStyleforFourthcard} />
                    </div>
                    <h1 className='mb-5 mx-10 justify-center flex  ' style={marginStyleforh4}>{props.course4}</h1>
                </div> </Link>

            </div>






        </>
    )
}

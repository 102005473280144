import React from 'react';

import './CSS.css'
import { UilArrowRight, UilProgrammingLanguage, UilClock, Uil3Plus, UilPhoneAlt, UilWhatsapp } from '@iconscout/react-unicons'
import { Link, NavLink, useNavigate, useLocation, useParams } from "react-router-dom";

export default function ReadMore() {
    return (
        <>
          {/* First Section(about netligent) Of About Us */}

          <section className=''>
                <div className='grid  -mt-14 lg:mt-0  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' text-start p-6 md:p-12 lg:p-16   order-1 back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:-mt-5'>Netligent and SIRT-E Bhopal </h1> 
                       
                        <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>Signing of A Memorandum Of Understanding(MOU)</h1>
                        <p className='mt-5  text-black '>Netligent Technologies and SIRT-E Bhopal  an Exciting Partnership with the Signing of a Memorandum of Understanding(MoU)
      Bringing Technological Innovation and Progress to Bhopal, India
         Netligent Technologies, a leading technology solutions provider, and SIRT-E Bhopal, a prominent entity in the education sector, have
          formalized a momentous collaboration through the signing of a Memorandum of Understanding (MoU). ‘
        With a shared vision to empower the local community through advanced technology and education, the
         partnership between Netligent Technologies and SIRT-E Bhopal promises to create a synergy that will 
         drive innovation and transformation. By leveraging Netligent Technology's expertise in digital
          solutions and SIRT-E
         Bhopal's commitment to academic excellence, this collaboration seeks to elevate the technological 
         infrastructure and educational standards within the region, fostering a culture of progress and 
         knowledge advancement.
       
</p>
                    </div>

                    <div className=' items-center flex justify-center  order-2 mx-6 md:mx-12 lg:mx-0   '>
                        <img src='Mou2.JPG' className='  w-full' alt='' />
                        
                    </div>
                </div>


                <div className='grid -mt-6 grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' mt-10 text-start p-6 md:p-12 lg:p-16  order-1 lg:order-2  back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:-mt-5'>Netligent and SIRT-E Bhopal  </h1> 
                       
                        <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>is Now Official Training Partner </h1>
                        <p className='mt-5  text-black '>"This partnership represents a significant step forward in our mission to revolutionize the educational
        experience in Bhopal," stated Mr Mudassir Hashmi, MD of Netligent Technologies. "We are thrilled to combine
         our technological prowess with SIRT-E Bhopal's esteemed educational legacy, ultimately enabling
          a dynamic and interactive learning environment that will equip students with the skills needed 
        Through this strategic partnership, Netligent Technology and Sirte Bhopal aim to usher in a new era
         of educational excellence, powered by technology-driven solutions that facilitate an enhanced learning 
         experience and contribute to the overall development of the Bhopal community.
        For media inquiries and further information, please contact:
       
      8349530962, 8349530982
       
</p>
                    </div>

                    <div className=' items-center flex justify-center  lg:order-1  order-2 mx-6 md:mx-12 lg:mx-0  '>
                        <img src='Mou1.JPG' className='  w-full' alt='' />
                        
                    </div>
                </div>


               

              
            </section>
            


        </>
    )
}




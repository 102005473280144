import React from "react";
import {

  Button,

} from "@material-tailwind/react";
import { Link } from "react-router-dom";
import { UilFacebookF, UilYoutube, UilInstagram, UilWhatsapp, UilLinkedin } from '@iconscout/react-unicons'

// function handleLinkClick(event) {
//   event.preventDefault(); // URL ko change karne se rokne ke liye

//   // Page ke top par jaane ke liye
//   window.scrollTo(0, 0);
// }


export default function Footer() {

  return (
    <>
      <footer class="bg-[#1A2942] mt-10 text-white">


        <div class="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8 ">

          <div class="lg:flex lg:items-start lg:gap-8 ">

            <div
              class="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16 "
            >
              <div class="col-span-2 ">
                <div>
                  <h2 class="text-2xl font-bold text-white">
                    Request A Demo Class!
                  </h2>

                  <p class="mt-4 text-white">
                    Send Your Messsage To Request A Demo Class And Get Experience After That Join Us
                  </p>
                </div>
              </div>

              <div class="col-span-2 lg:col-span-3 lg:flex lg:items-end">
                <form class="w-full">
                  <label for="UserEmail" class="sr-only"> </label>

                  <div
                    class="border border-white p-2  sm:flex sm:items-center sm:gap-4 "
                  >
                    <input
                      type="text"
                      id="UserEmail"
                      placeholder="Write Your Message"
                      class="w-full  border-none outline-none  sm:text-sm bg-[#1A2942]"
                    />

                    <button
                      class="mt-3 w-full bg-[#c43238] px-6 py-3 text-sm font-bold uppercase tracking-wide text-white transition-none hover:bg-[#1A2942] hover:border-white hover:border sm:mt-0 sm:w-auto sm:shrink-0"
                    >
                      Sign Up
                    </button>
                  </div>
                </form>
              </div>

              <div class="col-span-2 sm:col-span-1 lg:text-start lg:ml-10">
                <p class=" font-semibold text-white  text-xl">Services</p>

                <ul class="mt-6 space-y-4 text-sm ">
                  <li>
                    <a href="#ClassroomTraining" class="text-white transition hover:opacity-75">
                      Classroom Training
                    </a>
                  </li>

                  <li>
                    <a href='#InstructorLedOnline' class="text-white transition hover:opacity-75">
                      Instructor Led Online
                    </a>
                  </li>

                  <li>
                    <a href="#CorporateTraining" class="text-white transition hover:opacity-75">
                      Corporate Training
                    </a>
                  </li>

                  <li>
                    <a href="#1-on-1Training" class="text-white transition hover:opacity-75">
                      1-on-1 Training
                    </a>
                  </li>

                  <li>
                    <a href="#SelfpacedTraining" class="text-white transition hover:opacity-75">
                      Selfpaced Training
                    </a>
                  </li>

                  <li>
                    <a href="#Campus" class="text-white transition hover:opacity-75">
                      Campus Training
                    </a>
                  </li>
                </ul>
              </div>

              <div class="col-span-2 sm:col-span-1 lg:text-start lg:ml-10">
                <p class="font-semibold text-white text-xl">Company</p>

                <ul class="mt-6 space-y-4 text-sm">
                  
                  <li>
                  <Link to="https://www.netligent.com/About" target="_parent"  class="text-white transition hover:opacity-75">
                      About
                  </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Courses
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Career' target="_parent" class="text-white transition hover:opacity-75">
                      Career
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Gallery' target="_parent" class="text-white transition hover:opacity-75">
                      Gallery
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Contact' target="_parent" class="text-white transition hover:opacity-75">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="col-span-2 sm:col-span-1 lg:text-start lg:ml-10">
                <p class="font-semibold text-white text-xl">Cyber Security</p>

                <ul class="mt-6 space-y-4 text-sm">
                  <li>
                  <Link to='https://www.netligent.com/Courses'   target="_parent"  class="text-white transition hover:opacity-75 " rel="referrer">
                      Networking
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      CCNA
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Redhat
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Ubuntu
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Windows Server
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Ethical Hacking
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="col-span-2 sm:col-span-1 lg:text-start lg:ml-10">
                <p class="font-semibold text-white text-xl">Web Development</p>

                <ul class="mt-6 space-y-4 text-sm">
                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Python/Java
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Django
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      Mysql
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent" class="text-white transition hover:opacity-75">
                      HTML/CSS/BootStrap
                    </Link>
                  </li>

                  <li>
                  <Link to='https://www.netligent.com/Courses'  target="_parent"class="text-white transition hover:opacity-75">
                      JavaSript/React
                    </Link>
                  </li>
                </ul>
              </div>

              <div class="col-span-2 sm:col-span-1 lg:text-start ">
                <p class="font-semibold text-white text-xl">Location</p>

                <ul class="mt-6 space-y-4 text-sm">
                  <li>
                    <a href="#" class="text-white transition hover:opacity-75">
                      Building 4, Krishak Nagar,  Near <br></br> Karond Square, Karond Bhopal M.P.
                    </a>
                  </li>

                  <li>
                    <a href="#" class="font-semibold text-white text-xl">
                      Contact
                    </a>
                  </li>

                  <li>
                    <a href="#" class="text-white transition hover:opacity-75">
                      +91 8349530962 <br></br>
                      +91 8349530982
                    </a>
                  </li>
                </ul>
              </div>

              <ul
                class="col-span-2 flex justify-center gap-6 lg:col-span-5 "
              >
                <Link to='https://www.facebook.com/profile.php?id=61551170937539'>
                <li>
                  <  UilFacebookF
                    class="   w-7 h-7 grid place-content-center    hover:text-[#c43238]
                        transition ease-in-out duration-200"  />
                </li> </Link>

                <Link to='https://www.youtube.com/@Netligent'>
                <li>                    <UilYoutube
                  class="  w-8 h-8 grid place-content-center    hover:text-[#c43238]  
                        transition ease-in-out duration-200"/>
                </li> </Link>

                <Link to='https://www.instagram.com/netligenttechnologies/'>
                <li>
                  <UilInstagram
                    class="  w-7 h-8 grid place-content-center    hover:text-[#c43238]  
                        transition ease-in-out duration-200"/>
                </li> </Link>

                <Link to='https://www.linkedin.com/feed/'>
                <li>
                  <  UilLinkedin class="  w-7 h-8 grid place-content-center    hover:text-[#c43238]  
                        transition ease-in-out duration-200"/>
                </li> </Link>

              </ul>
            </div>
          </div>

          <div class="mt-8 border-t border-gray-100 pt-8">
            <div class="sm:flex sm:justify-between">
              <p class="text-xs text-white">
                &copy; 2023. Netligent Technologies LLP. All rights reserved.
              </p>

              <ul
                class="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end"
              >
                <li>
                  <a href="#" class="text-white transition hover:opacity-75">
                    Terms & Conditions
                  </a>
                </li>

                <li>
                  <a href="#" class="text-white transition hover:opacity-75">
                    Privacy Policy
                  </a>
                </li>

                <li>
                  <a href="#" class="text-white transition hover:opacity-75">
                    Cookies
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
import React from 'react';

//import './CSS.css'
import { UilArrowRight, UilProgrammingLanguage, UilClock, Uil3Plus, UilPhoneAlt, UilWhatsapp } from '@iconscout/react-unicons'
import { Link, NavLink, useNavigate, useLocation, useParams } from "react-router-dom";

export default function ReadMore() {
    return (
        <>
          {/* First Section(about netligent) Of About Us */}

          <section className=''>
                <div className='grid  -mt-14 lg:mt-0  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' text-start p-6 md:p-12 lg:p-16   order-1 back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:mt-5'>Netligent as program partner of  </h1> 
                      
                        <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>Mittal Institutes "Fete-Eutopia" </h1> 
                        <p className='mt-5  text-black '>

                        At Mittal College's New Year Carnival, Netligent Technologies LLP was invited and met many excited students who already knew about the great opportunities in IT courses.
Talking to these students was inspiring. They were really interested in technology and understood that IT courses have a bright future. Seeing their eagerness to learn more about Information Technology, we decided to organize a three-day demo class.
The students' response was fantastic. They came enthusiastically and actively took part, showing their passion to learn. It was amazing to see how much they wanted to know about technology.
In these demo classes, we talked about different IT courses, from basic programming to advanced technologies. We wanted to show them the many opportunities available in the IT field.





</p>
                    </div>

                    <div className='  items-center flex justify-center  order-2 mx-6 md:mx-12 lg:mx-0   '>
                        <img src='mittal2.jpeg' className='mt-10  w-full' alt='' />
                        
                    </div>
                </div>


                <div className='grid  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' mt-10 text-start p-6 md:p-12 lg:p-16  order-1 lg:order-2  back '>
                    <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:-mt-5'>Connects with Students  </h1> 
                      
                      <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>at Mittal College's New Year Carnival </h1> 

                     
                        <p className='mt-5  text-black '>
     
                         
"We were thrilled with how the students at Mittal College responded during our demo classes,"  at Netligent Technologies LLP. "Their interest in IT courses shows that there's a bright future in this field. We want to help these eager students build successful careers in technology."
Spending time with the bright students of Mittal College showed us how much they're interested in IT. Netligent Technologies LLP is excited to keep supporting these enthusiastic learners as they pursue careers in technology.
For more information or to learn about our efforts to help future technologists, follow Netligent Technologies LLP on social media.

       
</p>
                    </div>

                    <div className=' items-center flex justify-center  lg:order-1  order-2 mx-6 md:mx-12 lg:mx-0  '>
                        <img src='mittal1.jpeg' className='  w-full' alt='' />
                        
                    </div>
                </div>

                
              
            </section>
            


        </>
    )
}
import React from "react";
import { UilFacebook, UilInstagramAlt , UilLinkedin, UilYoutube, UilPhoneAlt, UilEstate   } from '@iconscout/react-unicons'
import { Link, NavLink } from "react-router-dom";
import {
  
  MobileNav,
  Typography,
  Button,
  IconButton,
  Card,
} from "@material-tailwind/react";

export default function Navbar({ fixed }) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-[#F2F4F8] mb-3">
        <div className="container px-4 mx-auto flex  items-center justify-between">
          <div className=" relative hidden sm:block  w-auto   ">
            <Link to="https://netligent.com/"
              className="text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase text-black"
              href="#pablo"
            >
              <img src="logo.png" className="w-52"></img>
            </Link>
          
          </div>
          
            <ul className="flex   list-none justify-center">
      
              <li className="nav-item">
                <Link to="https://www.facebook.com/profile.php?id=61551170937539"
                  className="px-3 py-2 flex items-center   hover:opacity-75"
                  href="#pablo"
                >
                  <UilFacebook className=" text-[#1A2942] w-7 h-7"/>
                 
                </Link>
              </li>
              <li className="nav-item">
              <Link to="https://www.instagram.com/netligenttechnologies/"
                  className="px-3 py-2 flex items-center   hover:opacity-75"
                  href="#pablo"
                >
                  <UilInstagramAlt className=" text-[#1A2942]  w-7 h-7"/>
                  
                </Link>
              </li>
              <li className="nav-item">
              <Link to ="https://www.linkedin.com/company/99984299/admin/feed/posts/"
                  className="px-3 py-2 flex items-center   hover:opacity-75"
                  href="#pablo"
                >
                  <UilLinkedin className=" text-[#1A2942]  w-7 h-7"/>
                 
                </Link>
              </li>
              <li className="nav-item">
              <Link to ="https://www.youtube.com/channel/UCnnWrVV25xzw8Dhq_WsHcYw"
                  className="px-3 py-2 flex items-center   hover:opacity-75"
                  href="#pablo"
                >
                  <UilYoutube  className=" text-[#1A2942]   w-7 h-7"/>
                 
                </Link>
              </li>
              <li className="nav-item block md:hidden">
              <Link to ="https://netligent.com/Contact"
                  className="px-3 py-2 flex items-center   hover:opacity-75"
                  href="#pablo"
                >
                  <UilPhoneAlt  className=" text-[#1A2942]    w-7 h-7"/>
                 
                </Link>

              
              </li>

              <li className="nav-item">
              <Link to ="https://netligent.com/Contact"
                  className="px-3  flex items-center   hover:opacity-75"
                  href="#pablo"
                >
                 <Button
              variant="gradient"
              size="sm"
              className="hidden md:inline-block text-white  border-2 border-[#1A2942]   rounded-full  hover:text-[#1A2942] bg-[#1A2942]  hover:bg-white
              "
            >
              <span>Contact Us</span>
            </Button>
                </Link>
              </li>
            </ul>
          </div>
        
      </nav>
    </>
  );
}
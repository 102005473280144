import React from 'react';
import { Link, NavLink } from "react-router-dom";
export default function FirstBlog() {
  return (
    <>
      {/* <MetaData title="Netligent - Blog " ></MetaData> */}
      {/* First section start */}
      <section className=' '>
       <div className='grid grid-cols-1 text-start justify-start sm:mx-10 md:mx-24 mt-5 font-sanf'>
        <div className='flex '>
        <img src="logo2.png" className='justify-center w-24 sm:w-24 md:w-28'/>
        <div className=' sm:p-1 p-0 block'>
          <h1 className=' text-3xl md:text-5xl text-[#1A2942]  justify-center font-semibold'>Netligent Blog</h1>
          <p className='mt-1 sm:mt-3 text-base sm:text-xl text-gray-700 justify-center'>MOU | New Courses | Workshop | Seminar </p>
        </div>
        </div>
       </div>


       <div className=' grid grid-cols-1   border-b border-gray-400 mx-7  sm:mx-14 md:mx-28 mt-5 text-start p-2 text-sanf'>
        <ul className=' flex flex-row gap-8 text-xl'>
         <Link to="https://netligent.com/"> <li className=' hover:text-[#c43238]'>Home</li></Link>
         <Link to="https://netligent.com/Contact"> <li className=' hover:text-[#c43238]'>Contact</li></Link>
        </ul>
       </div>
      </section>
      {/* First section end */}
    </>
  )
}

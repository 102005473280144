import React from 'react';
import { Link } from 'react-router-dom';
import './main.css'
import {

  MobileNav,
  Typography,
  Button,
  IconButton,
  Card,
} from "@material-tailwind/react";
export default function MOU() {

  

  
  return (
    <>
      {/* <MetaData title="Netligent - Blog " ></MetaData> */}
      {/* MOU start */}
      {/* <section className=''>
        <div className='grid  grid-cols-1   font-sanf  bg-[#F2F4F8] overflow-hidden mt-16   ' >


          

          <div className=' text-start p-6 md:p-12 lg:p-16  '>
            <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold  text-[#1A2942]  mt-10 lg:mt-0'>Netligent and SIRTE Bhopal</h1>

            <h1 className='text-[#c43238] text-2xl  md:text-3xl lg:text-2xl font-bold'> Signing of A Memorandum Of Understanding(MOU)</h1>
            <p className='mt-5  text-black '>
              Netligent, a leading technology solutions provider, and Sirte Bhopal, a prominent entity in the education sector, 
              have formalized a momentous collaboration through the signing of a Memorandum of Understanding (MoU).<br></br> <br></br>
              With a shared vision to empower the local community through advanced technology and education, the partnership 
              between Netligent and SIRTE Bhopal promises to create a synergy that will drive innovation and transformation. 
              By leveraging Netligent expertise in digital solutions and SIRTE Bhopal's commitment to academic excellence, 
              this collaboration seeks to elevate the technological infrastructure and educational standards within the region, fostering 
              a culture of progress and knowledge advancement.<br></br> <br></br>
              "This partnership represents a significant step forward in our mission to revolutionize the educational experience in Bhopal," 
              stated Mudassir Ahmed Hashmi, CEO of Netligent Technology. "We are thrilled to combine our technological prowess with SIRTE Bhopal's esteemed 
              educational legacy, ultimately enabling a dynamic and interactive learning environment that will equip students with the skills 
              needed for success in the digital age."<br></br><br></br>
              Expressing enthusiasm for the partnership, [Name], Director of SIRTES Bhopal, commented, "By integrating the latest technological 
              advancements into our curriculum, we are committed to providing our students with a comprehensive and future-oriented education. 
              This collaboration with Netligent aligns seamlessly with our vision, enabling us to create a transformative learning
               ecosystem that prepares students for the challenges and opportunities of tomorrow."<br></br>
              Through this strategic partnership, Netligent and SIRTE Bhopal aim to usher in a new era of educational excellence, 
              powered by technology-driven solutions that facilitate an enhanced learning experience and contribute to the overall development of the Bhopal community.
              For media inquiries and further information, please contact:
              
              +91 8349530962, +91 8349530982
            </p>

            <div className=' items-center flex justify-center   '>
            <img src='mou1.jpeg' className=' ' />

          </div>
          </div>
        </div>




      </section> */}

      <section>
        <div className=' grid grid-cols-1 lg:grid-cols-3  font-sanf mt-16 mx-4 sm:mx-32 md:mx-48 lg:mx-16 gap-10 md:gap-5 lg:gap-5'>

        <div className=' block  '>
            <div className=' box1  border-whihte border'>
              <img src="t1.jpeg" className=' ' />
              <div className='p-4'>
                <h1 className='text-start text-lg text-[#1A2942] font-semibold border-b border-gray-300 p-1'>A Memorable  trip to Ratapani Wildlife Sanctuary of Netligent Staff</h1>

                <p className=' text-start p-1  '>
                This was a memorable trip for the staff members of Netligent Technologies.
                         We began the journey by gathering at the office at 9:00 in the morning, 
                         and then at 9:10 AM, we set off for the trip. We met the rest of our colleagues
                          at Ashoka Garden and embarked on the trip together... </p>
              
              <Link to='/Ratapani-Trip-Details'>
                                <Button 
                  variant="gradient"
                  size="sm"
                  className="flex text-white justify-start mt-3  border-2 border-[#1A2942] rounded-md  hover:text-[#1A2942] bg-[#1A2942]  hover:bg-white 
              "
                >
                  <span>Read</span>
                </Button> </Link>

              </div>

            </div>
          </div>


          <div className=' block  '>
            <div className=' box1  border-whihte border'>
              <img src="mittal1.jpeg" className=' ' />
              <div className='p-4'>
                <h1 className='text-start text-lg text-[#1A2942] font-semibold border-b border-gray-300 p-1'>Netligent as program partner of Mittal Institutes "Fete-Eutopia"
                </h1>

                <p className=' text-start p-1  '>
                The Java workshop at SIRT-E College Bhopal, held on December 12-14, was a resounding success.
                 Over three days, participants immersed themselves in practical Java programming, gaining 
                 valuable insights into its versatile applications in software development.</p>
              
              <Link to='/MittalInstitute-Fete-Details'>
                                <Button 
                  variant="gradient"
                  size="sm"
                  className="flex text-white justify-start mt-3  border-2 border-[#1A2942] rounded-md  hover:text-[#1A2942] bg-[#1A2942]  hover:bg-white 
              "
                >
                  <span>Read</span>
                </Button> </Link>

              </div>

            </div>
          </div>



          <div className=' block  '>
            <div className=' box1  border-whihte border'>
              <img src="sirte.jpg" className=' ' />
              <div className='p-4'>
                <h1 className='text-start text-lg text-[#1A2942] font-semibold border-b border-gray-300 p-1'>Netligent Conducted A Workshop<br></br>on Java Essentials at SIRT-E Bhopal
                </h1>

                <p className=' text-start p-1  '>
                The Java workshop at SIRT-E College Bhopal, held on December 12-14, was a resounding success.
                 Over three days, participants immersed themselves in practical Java programming, gaining 
                 valuable insights into its versatile applications in software development.</p>
              
              <Link to='/SIRTE-Workshop-Details'>
                                <Button 
                  variant="gradient"
                  size="sm"
                  className="flex text-white justify-start mt-3  border-2 border-[#1A2942] rounded-md  hover:text-[#1A2942] bg-[#1A2942]  hover:bg-white 
              "
                >
                  <span>Read</span>
                </Button> </Link>

              </div>

            </div>
          </div>





          <div className=' block  '>
            <div className=' box1  border-whihte border'>
              <img src="w1.jpeg" className=' ' />
              <div className='p-4'>
                <h1 className='text-start text-lg text-[#1A2942] font-semibold border-b border-gray-300 p-1'>Netligent Conducted A Workshop<br></br> on AWS Cloud at SAGE University Bhopal
                </h1>

                <p className=' text-start p-1  '>
                The two-day hands-on workshop on AWS held on October 30, 31 was a resounding success. Participants
                 delved deep into the world of AWS services, mastering practical skills, and gaining valuable insights into cloud computing.</p>
              

                 <Link to='/Sage-Workshop-Details'>
                <Button
                  variant="gradient"
                  size="sm"
                  className="flex text-white justify-start mt-3  border-2 border-[#1A2942] rounded-md  hover:text-[#1A2942] bg-[#1A2942]  hover:bg-white 
              "
                >
                  <span>Read</span>
                </Button> </Link>

              </div>

            </div>
          </div>






          
          <div className=' block '>
            <div className=' box1  border-whihte border'>
              <img src="mou1.jpeg" className=' ' />
              <div className='p-4'>
                <h1 className='text-start text-lg text-[#1A2942] font-semibold border-b border-gray-300 p-1'>Netligent and SIRTE Bhopal
                  Signing of A<br></br> Memorandum Of Understanding(MOU)
                </h1>

                <p className=' text-start p-1  '>
                  Netligent, a leading technology solutions provider, and SIRTE Bhopal, a prominent entity in the education sector,
                  have formalized a momentous collaboration through the signing of a Memorandum of Understanding (MoU).</p>
              
              <Link to='/MOU-With-SIRTE-Details'>
                <Button
                  variant="gradient"
                  size="sm"
                  className="flex text-white justify-start mt-3  border-2 border-[#1A2942] rounded-md  hover:text-[#1A2942] bg-[#1A2942]  hover:bg-white 
              "
                >
                  <span>Read</span>
                </Button> </Link>

              </div>

            </div>
          </div>

          

          
          

          


          </div>
      </section>
      {/* First section end */}
    </>
  )
}

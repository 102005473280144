import './App.css';
import Header from "./components/Header/Header"
import Footer from './components/Footer/Footer';

import BlogPage from './pages/blog/link'
import ReadMore_Link from './pages/blog/Read_More/link';
import ReadMore2_Link from './pages/blog/Read_More/Sage-Workshop/link';
import ReadMore3_Link from './pages/blog/Read_More/SIRTE-Workshop/link';
import ReadMore_Trip_Link from './pages/blog/Read_More/Trip/link';
import ReadMore_Mittal_Link from './pages/blog/Read_More/Mittal/link';


import {
  BrowserRouter as Router,
  Route,
  Routes,

} from "react-router-dom";

const Routing = () => {
  

  // const history = useNavigate();
  // const dispatch = useDispatch();
  // const user = "admin";
  // console.log(user)
  return (
    <Routes>
      
      <Route path="/" element={<BlogPage />}>  </Route>
      {/* <Route path="/Courses" element={<CourseLink />}>  </Route> */}
      <Route path="/MOU-With-SIRTE-Details" element={<ReadMore_Link/>}>  </Route>
      <Route path="/Sage-Workshop-Details" element={<ReadMore2_Link/>}>  </Route>
      <Route path="/SIRTE-Workshop-Details" element={<ReadMore3_Link/>}>  </Route>
      <Route path="/Ratapani-Trip-Details" element={<ReadMore_Trip_Link/>}>  </Route>
      <Route path="/MittalInstitute-Fete-Details" element={<ReadMore_Mittal_Link/>}>  </Route>

      
      </Routes>
  )}

function App() {
  return (
    <div className="App">
      <Router>
        <Header/>
       
        <Routing />
        <Footer/>
      </Router>
      
      
      
      
    </div>
  );
}

export default App;

import React from 'react';

import '../CSS.css'
import { UilArrowRight, UilProgrammingLanguage, UilClock, Uil3Plus, UilPhoneAlt, UilWhatsapp } from '@iconscout/react-unicons'
import { Link, NavLink, useNavigate, useLocation, useParams } from "react-router-dom";

export default function Read_More2() {
    return (
        <>
          {/* First Section(about netligent) Of About Us */}

          <section className=''>
                <div className='grid  -mt-14 lg:mt-0  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' text-start p-6 md:p-12 lg:p-16   order-1 back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:-mt-5'> Netligent Conducted A Workshop </h1> 
                       
                        <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>at SAGE University Bhopal</h1>
                        <p className='mt-5  text-black '>Empowering Future Innovators: Netligent Technologies LLP Conducts a Transformative Two-Day
             Cloud Computing Workshop at Sage University Bhopal
            Unlocking the Potential of Cloud Computing: A Hands-On Experience
            Netligent Technologies LLP, known for using new technological advancements, recently
             organized a two-day immersive workshop on Cloud Computing at the Sage University. 
             This collaborative initiative aimed to empower the tech enthusiasts and beginning
              professionals with practical insights and hands-on experiences in leveraging the
               transformative power of cloud technologies.
            The workshop, held at the state-of-the-art facilities of Sage University, brought
             together enthusiastic learners, passionate about exploring the domains of cloud computing. 
             Facilitated by experts from Netligent Technologies LLP, the sessions were thoroughly crafted
              to offer a comprehensive understanding of the foundational principles and advanced applications 
              of cloud computing.
       
</p>
                    </div>

                    <div className=' items-center flex justify-center  order-2 mx-6 md:mx-12 lg:mx-0   '>
                        <img src='sage1.JPG' className='  w-full' alt='' />
                        
                    </div>
                </div>


                <div className='grid -mt-9 grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' mt-10 text-start p-6 md:p-12 lg:p-16  order-1 lg:order-2  back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#1A2942]  mt-10 lg:-mt-5'> Workshop Details:  </h1> 
                       
                       
                        <p className='mt-5  text-black  '>
                            <span className='text-[#c43238] text-2xl'>•	Dates:
                         </span>
                         <span className='text-[#1A2942] text-2xl ml-5'> 30/10/2023 , 31/10/2023</span> <br></br>
                         <span className='text-[#c43238] text-2xl  '>•	Time:</span>
                         <span className='text-[#1A2942] text-2xl ml-7 '> 11:00 AM to 03:00 PM</span><br></br>
                         <span className='text-[#c43238] text-2xl'> •	Venue:</span> 
                         <span className='text-[#1A2942] text-2xl ml-5'>Sage University, Bhopal, M.P.</span><br></br>
                         <span className='text-[#c43238] text-2xl'>•	Host:</span> 
                         <span className='text-[#1A2942] text-2xl ml-9'>Netligent Technology LLP,Bhopal</span> <br></br>

                        <h1 className='text-2xl  md:text-3xl lg:text-3xl font-semibold   text-[#1A2942]  mt-10 lg:mt-5'> For More Information Contact Us :  </h1> 

       
                        <p className='text-[#c43238] text-2xl mt-3'>+91 83495 30962, +91 83495 30982</p>
       
</p>
                    </div>

                    <div className=' items-center flex justify-center  lg:order-1  order-2 mx-6 md:mx-12 lg:mx-0  '>
                        <img src='sage5.JPG' className='  w-full ' alt='' />
                        
                    </div>
                </div>


                <div className='grid  -mt-7  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' text-start p-6 md:p-12 lg:p-16   order-1 back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:mt-0'>  Fostering Skill Development and Innovation </h1> 
                       
                        {/* <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>at SAGE University Bhopal</h1> */}
                        <p className='mt-5  text-black '> The event wasn't merely a passive learning experience; it was a platform for participants 
            to actively engage, experiment, and innovate. Attendees had the opportunity to explore and
             navigate through the tools and functionalities offered by leading cloud service providers,
              gaining invaluable proficiency that is increasingly sought after in today's tech-driven landscape.
            "The workshop was an eye-opener! I now feel equipped and inspired to harness the power of cloud
             computing in my projects. Thank you, Netligent Technologies LLP, for this invaluable experience!" - Participant
            Empowering the Tech Visionaries of Tomorrow
            Netligent Technologies LLP's commitment to fostering knowledge and innovation was palpable 
            throughout the workshop. By empowering the next generation of tech enthusiasts with the tools
             and insights needed to navigate the complexities of cloud computing, this collaborative effort 
             with Sage University  for creating a future-ready workforce adept at harnessing the potential
               of cutting-edge technologies.

           
       
</p>
                    </div>

                    <div className=' items-center flex justify-center  order-2 mx-6 md:mx-12 lg:mx-0   '>
                        <img src='sage2.JPG' className='  w-full' alt='' />
                        
                    </div>
                </div>


                
                <div className='grid -mt-7 grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' mt-10 text-start p-6 md:p-12 lg:p-14  order-1 lg:order-2  back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#1A2942]  mt-10 lg:-mt-5'>  The World of Cloud Technologies  </h1> 
                       
                       
                        <p className='mt-5  text-black  '>
                        Over two days, the workshop covered a lot about cloud technology. It talked about the basics
             of cloud computing and went into detail about different types of cloud services
              . It also gave a good look at big cloud platforms like Amazon Web
               Services (AWS), Microsoft Azure, and Google Cloud Platform (GCP). People taking part got
                to see real examples, do some practice activities, and talk with each other to learn how 
                to use these ideas in real life.
                In conclusion, the two-day workshop on cloud computing conducted by Netligent Technologies
             LLP at Sage University Bhopal stands as a proof to the transformative power of education and
              hands-on learning. It not only equipped participants with technical expertise but also 
              ignited a spark of innovation and curiosity that will undoubtedly shape the future of
               technology.       
</p>
                    </div>

                    <div className=' items-center flex justify-center  lg:order-1  order-2 mx-6 md:mx-12 lg:mx-0  '>
                        <img src='sage3.JPG' className='  w-full ' alt='' />
                        
                    </div>
                </div>


               

              
            </section>
            


        </>
    )
}




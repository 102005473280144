import React, {useEffect} from 'react';
import ScrollReveal from 'scrollreveal';



import '../CSS.css'

import { UilArrowRight, UilProgrammingLanguage, UilClock, Uil3Plus, UilPhoneAlt, UilWhatsapp } from '@iconscout/react-unicons'
import { Link, NavLink, useNavigate, useLocation, useParams } from "react-router-dom";




export default function ReadMore3() {
    useEffect(() => {
        ScrollReveal().reveal('h1', {
          duration: 1000,
          origin: 'left', // or 'top', 'right', 'bottom', 'center', etc.
          distance: '20px',
          delay: 200,
        });
      }, []);
    return (
        <>
          {/* First Section(about netligent) Of About Us */}

          <section className=''>
                <div className='grid  -mt-14 lg:mt-0  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' text-start p-6 md:p-12 lg:p-16   order-1 back ' id='div1'>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:-mt-5' >Netligent Hosts Successful  Workshop </h1> 
                       
                        <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'> at SIRTE College, Bhopal.</h1>
                        <p className='mt-5  text-black '>

                        In a bid to foster innovation and empower the budding technocrats, Netligent Technologies LLP recently conducted an enriching Java workshop at Sagar Institute of Research & Technology (SIRT) College in Bhopal. The workshop, spanning over three days, proved to be an immersive learning experience for the enthusiastic students pursuing computer science and programming.
Led by industry experts and seasoned Java developers from Netligent Technologies LLP, the workshop aimed to bridge the gap between theoretical knowledge and practical application, offering a comprehensive insight into the dynamic world of Java programming. Through a blend of interactive sessions, hands-on exercises, and real-world case studies, participants were exposed to the nuances of Java, from its foundational concepts to its advanced functionalities.
The event commenced with an illuminating keynote address, setting the tone for an engaging learning journey ahead. The attendees delved into diverse topics, ranging from the basics of Java syntax and data structures to the intricacies of object-oriented programming and application development. 



</p>
                    </div>

                    <div className=' items-center flex justify-center  order-2 mx-6 md:mx-12 lg:mx-0   '>
                        <img src='sirte.jpg' className='  w-full' alt='' />
                        
                    </div>
                </div>


                <div className='grid -mt-32 grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' mt-10 text-start p-6 md:p-12 lg:p-16  order-1 lg:order-2  back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:-mt-5'>Netligent and SIRT-E Bhopal  </h1> 
                       
                        <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>is Now Official Training Partner </h1>
                        <p className='mt-5  text-black '>

                        The workshop also spotlighted industry best practices, empowering the students with valuable insights into the contemporary Java ecosystem and its relevance in today's tech landscape.
What set this workshop apart was its interactive nature. Participants were encouraged to actively engage in coding challenges and collaborative projects, fostering a dynamic learning environment that encouraged creativity and problem-solving skills.
 The mentors from Netligent Technologies LLP provided personalized guidance, ensuring that every participant received the necessary support to grasp the concepts effectively.
The culmination of the workshop witnessed the showcasing of innovative projects developed by the attendees, reflecting their newfound proficiency in Java programming. The enthusiasm and dedication displayed by the students were commendable,
 showcasing their readiness to embrace the evolving realm of technology.
Netligent Technologies LLP, known for its commitment to technological excellence and knowledge dissemination, reaffirmed its dedication to nurturing talent and empowering the next generation of tech leaders through such impactful initiatives.

       
</p>
                    </div>

                    <div className=' items-center flex justify-center  lg:order-1  order-2 mx-6 md:mx-12 lg:mx-0  '>
                        <img src='Java2.jpeg' className='  w-full' alt='' />
                        
                    </div>
                </div>


               

              
            </section>
            


        </>
    )
}


import React from 'react';

//import './CSS.css'
import { UilArrowRight, UilProgrammingLanguage, UilClock, Uil3Plus, UilPhoneAlt, UilWhatsapp } from '@iconscout/react-unicons'
import { Link, NavLink, useNavigate, useLocation, useParams } from "react-router-dom";

export default function ReadMore_Trip() {
    return (
        <>
          {/* First Section(about netligent) Of About Us */}

          <section className=''>
                <div className='grid mt-5  lg:mt-0  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' text-start p-6 md:p-12 lg:p-16   order-1 back '>
                        <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:'>A Memorable  Trip to  </h1> 
                      
                        <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'> Ratapani Wildlife Sanctuary</h1> 
                        <p className='mt-5  text-black '>

                        This was a memorable trip for the staff members of Netligent Technologies.
                         We began the journey by gathering at the office at 9:00 in the morning, 
                         and then at 9:10 AM, we set off for the trip. We met the rest of our colleagues
                          at Ashoka Garden and embarked on the trip together. Finally, the trip started as
                           we set out towards our destination.
                After a while, around 40 kilometers into our journey, we made a stop for breakfast. 
            Our colleagues brought breakfast items like Daal pakvaan ,dhoklas, and pakoras.. We all
            enjoyed the breakfast and resumed our journey towards our destination. However, we weren't
                quite sure of the route since it was unfamiliar to us. We then resorted to using the trusty tool, 
            Google Maps, to guide us.


</p>
                    </div>

                    <div className=' items-center flex justify-center  order-2 mx-6 md:mx-12 lg:mx-0   '>
                        <img src='t1.jpeg' className=' mt-7 w-full' alt='' />
                        
                    </div>
                </div>


                <div className='grid -mt-6 grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' mt-10 text-start p-6 md:p-12 lg:p-16  order-1 lg:order-2  back '>
                    <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:mt-0'>This was a Memorable Trip  </h1> 
                      
                      <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>for the staff members of Netligent Technologies</h1> 
                        <p className='mt-5  text-black '>
                        The location we followed was "Bhopal to Rata Pani."
                Along the way, we stumbled upon a beautiful village with a stream where some children 
                    were bathing. We saw village women doing their daily tasks in the beautiful surroundings of green 
                    fields with flowing water. Even though we encountered many difficulties like bumpy roads and holes
                in the road, dust on the road we kept going. and moments where we were hesitant to continue due to
                    the condition of the road, There was a ditch where we were afraid to get our car stuck. However,
                     brave Sir didn't give up and proved that victory lies beyond fear[डर के आगे जीत है]. 
                        We were about to reach our final destination when we found out that the road ahead was closed.
       
</p>
                    </div>

                    <div className=' mt-3 items-center flex justify-center  lg:order-1  order-2 mx-6 md:mx-12 lg:mx-0  '>
                        <img src='t3.jpeg' className='  w-full' alt='' />
                        
                    </div>
                </div>

                <div className='grid mt-5  lg:mt-0  grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' text-start p-6 md:p-12 lg:p-16   order-1 back '>
                    <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:mt-5'>One-Day Journey </h1> 
                       
                       <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'>Our Endless Delight!     </h1>
                        <p className='mt-5  text-black '>
                        Even then, we didn't give up and started our journey again, searching for the Rata 
                        Pani Wildlife Sanctuary. 

After encountering those challenges and captivating sights, we returned, and after traveling 
for a long time, upon seeing the beautiful  mountains, we realized we were on the wrong path again. 
Then, we faced another problem: the second car's tire got punctured. Our Coll worked hard to change the car's tire.
 After that, we set off again as we continued our journey in search of the Rata Pani Wildlife Sanctuary.

Finally, we reached our destination only to find out that the Rata Pani Wildlife Sanctuary had been closed for
 quite some time.


</p>
                    </div>

                    <div className=' items-center flex justify-center  order-2 mx-6 md:mx-12 lg:mx-0   '>
                        <img src='out.jpeg' className='  w-full' alt='' />
                        
                    </div>
                </div>



                <div className='grid -mt-6 grid-cols-1 lg:grid-cols-2   font-sanf  bg-[#F2F4F8] overflow-hidden   ' >
                    <div className=' mt-10 text-start p-6 md:p-12 lg:p-16  order-1 lg:order-2  back '>
                    <h1 className='text-2xl  md:text-3xl lg:text-4xl font-bold   text-[#c43238]  mt-10 lg:mt-3'> A Day's Adventure,  </h1> 
                      
                      <h1 className='text-[#1A2942] text-2xl  md:text-3xl lg:text-3xl font-bold'> A Lifetime of Memories</h1> 
                      
                        <p className='mt-5  text-black '>

     Even though we reached our intended final destination, we couldn't explore it. We decided to 
 start a new journey and  another to visit Bhimbetka. When we arrived there, we clicked a lot of photos and had
  a great time. After that, feeling quite hungry, we found a Punjabi restaurant 
"We ate at a Punjabi restaurant, and the food was very good.. Satisfied, we headed back home, stopping at
 Obedullaganj to enjoy some Mawa Bati. Then, once again, we set out towards our homes, and finally, at 9 o'clock 
 in the evening, our trip concluded. Although our initial trip was supposed to be a 70-kilometer journey, in our quest to find Raita Pani, we covered over 200 kilometers.

"This trip was very memorable."
     
</p>
                    </div>

                    <div className=' items-center flex justify-center  lg:order-1  order-2 mx-6 md:mx-12 lg:mx-0  '>
                        <img src='t6.jpeg' className='hover:zoom-in mt-6 w-full' alt='' />
                        
                    </div>
                </div>
              
            </section>
            


        </>
    )
}